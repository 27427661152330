import '../styles/budget-optimization.sass';
import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { max } from 'd3-array';
import {
  stack,
  select,
  scaleUtc,
  scaleLinear,
  scaleOrdinal,
  area,
  axisBottom,
  axisLeft,
  line,
  curveCatmullRom,
  Series,
  SeriesPoint,
  extent,
} from 'd3';
import 'd3-selection-multi';
import { chartShortDate, getDimensions } from 'charts/utils';
import { MetricsFormattingConstants, DSP, GOAL_TYPES } from 'constantsBase';
import { Statbox } from 'containers/StrategyAnalytics/components/Statbox';
import { getSingleRevTypeAndValue } from 'containers/StrategyWizard/utils';
import { includeAdditionalMetrics } from 'containers/StrategyAnalytics/utils/metricsUtils';
import { AggregatedAnalyticsData } from 'containers/StrategyAnalytics/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Strategy, EnvironmentVariables } from 'utils/types';
import {
  createAxisLabel,
  createHorizontalLine,
  initHoverBehavior,
  buildLegend,
  createFlightAttachmentMarker,
  areaGeneratorForSinglePointData,
  getPacingCopy,
  getPacingUnitsFormat,
  createEventBudgetDeliveredMarker,
  getPerformanceLabel,
  getPerformanceFormat,
} from './helpers';
import {
  BudgetOptimizationVizProps,
  TransformedData,
  GenericSelection,
  PacingDatum,
  PerformanceDatum,
  StackingDatum,
} from './types';
import {
  STACKED_AREA_CHART_COLORS,
  MARGINS,
  LINECHART_HEIGHT,
  STACKEDCHART_HEIGHT,
  BUDGET_OPT_ANALYTICS_WIKI,
  BudgetTypeMapping,
} from './constants';

const getChartDimensions = (selector: string) => {
  const { clientWidth } = getDimensions(selector) as HTMLDivElement;
  const vizHeight = STACKEDCHART_HEIGHT + (2 * LINECHART_HEIGHT);
  return [clientWidth, vizHeight];
};

const buildLayers = (selector, width, height) => {
  const container = select(selector);
  const svg = container.select('svg')
    .attr('width', width)
    .attr('height', height);
  return svg;
};

const buildViz = (
  container: GenericSelection,
  svg: GenericSelection,
  w: number,
  h: number,
  data: TransformedData,
  strategy: Strategy,
  stratData: AggregatedAnalyticsData,
) => {
  const { revenueType } = getSingleRevTypeAndValue(strategy.revTypeConfig);
  if (svg) {
    const {
      performance: cumulativePerformanceData,
      pacing: pacingData,
      totalAccumulatedBudget,
      cumulativePacingChildren: cumulativePacingChildrenData,
      stacked,
      metaData: {
        dateRange,
        budgetType,
        strategyGoalType, // use for labels
        goalTypeKey, // use to access datum
        estimatedKPIKey,
        yScaleUnits,
        childExtIdToSettings,
        flightAttachmentTime,
        cloneToOrig,
        origToClone,
      },
      negativeBudgetDate,
      pacingBounds = [],
    } = data;
    const validPacingBounds = pacingBounds.filter((datum) => datum.lowerBound !== null && datum.upperBound !== null);
    const width = w - MARGINS.left - MARGINS.right;
    const height = h - MARGINS.top - MARGINS.bottom;

    const accessorUnit = BudgetTypeMapping[budgetType];

    const goalObj = _.get(stratData, 'metadata.goal');
    const singleDatum = _.chain(stratData).get('dailyData').head().value();
    const metrics = includeAdditionalMetrics(goalObj, singleDatum);

    const isCustomGoal = !goalObj.isSystemGoal || _.isEqual(GOAL_TYPES.impactOutcome.value, strategyGoalType);

    const yDimensions = {
      pacingLineChart: height * 0.2,
      divider1: height * 0.1,
      stackedAreaChart: height * 0.4,
      divider2: height * 0.1,
      performanceLineChart: height * 0.2,
    };

    const yRanges = {
      pacingLineChart: [yDimensions.pacingLineChart, 0],
      stackedAreaChart: [yDimensions.stackedAreaChart, 0],
      performanceLineChart: [yDimensions.performanceLineChart, 0],
    };

    /*
    x-scale and x-axis
    */
    const x = scaleUtc<number>()
      .range([0, width])
      .domain(dateRange);

    svg.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(${MARGINS.left},${MARGINS.top + height})`)
      .call(axisBottom(x).ticks(6).tickFormat((v) => chartShortDate(v as Date)));

    if (negativeBudgetDate) {
      createEventBudgetDeliveredMarker(svg, yDimensions, x, moment(negativeBudgetDate));
    }
    /*
    Pacing Chart
    */
    const pacingLineChart = svg.select('#pacingLineChart')
      .attr('transform', `translate(${MARGINS.left},${MARGINS.top})`);

    const yPacingLine = scaleLinear()
      // consider both the pacing data and bounds to get y max
      .domain([0, max([
        ..._.map(pacingData, (d) => d[accessorUnit]),
        ..._.map(validPacingBounds, (d) => d.upperBound),
      ])])
      .range(yRanges.pacingLineChart)
      // this will prevent bounds from going negative in budget_delivered scenarios
      .clamp(true);

    // pacing y-axis
    const pacingYAxis = pacingLineChart.append('g')
      .attr('class', 'axis axis--y')
      .call(
        axisLeft(yPacingLine)
          .ticks(3)
          .tickFormat((d) => {
            const pacingFormat = getPacingUnitsFormat(budgetType, revenueType, metrics);
            return _.upperCase(numeral(d).format(pacingFormat));
          }),
      );

    // units label for y axis
    if (!_.isNil(yScaleUnits.pacing) && !revenueType) {
      pacingYAxis.append('text')
        .text(yScaleUnits.pacing)
        .attr('class', 'unit-label')
        .attr('dy', -10)
        .attr('dx', -5);
    }
    // pacing area
    pacingLineChart.append('path')
      .datum(pacingData)
      .attr('id', 'pacing-area')
      .attr('d', area<PacingDatum>()
        .x((d) => x(d.date))
        .y0(() => yPacingLine(0))
        .y1((d) => yPacingLine(d[accessorUnit])));

    // pacing line
    pacingLineChart.append('path')
      .datum(pacingData)
      .attr('id', 'pacing-line')
      .attr('d', line<PacingDatum>()
        .x((d) => x(d.date))
        .y((d) => yPacingLine(d[accessorUnit])));

    pacingLineChart
      .selectAll('.pacing-bound')
      .data(['lowerBound', 'upperBound'])
      .join('path')
      .datum((boundType) => _.map(validPacingBounds, (d) => ({ date: d.date, bound: d[boundType] })))
      .attr('class', 'pacing-bound')
      .attr('d', line<{ date: Moment, bound: number }>()
        .curve(curveCatmullRom)
        .x((d) => x(d.date))
        .y((d) => yPacingLine(d.bound)));

    createAxisLabel(_.startCase(accessorUnit), pacingLineChart, yRanges.pacingLineChart);

    /*
    Stacked Area Chart
    */
    const colorScale = scaleOrdinal<string>(STACKED_AREA_CHART_COLORS);
    const yStackedArea = scaleLinear().range(yRanges.stackedAreaChart);

    // we need two separate stack generators to create proper strokes, shading, etc. with ICOs
    const stackGeneratorICOMerged = stack<StackingDatum, string>();
    const stackGeneratorICOSeparated = stack<StackingDatum, string>();

    const areaGenerator = (_.size(stacked.dataWithICOMerged) === 1) ? areaGeneratorForSinglePointData(x, yStackedArea) : area<SeriesPoint<StackingDatum>>()
      .defined((d) => !_.isNaN(d[0]) && !_.isNaN(d[1]))
      .x((d) => x(d.data.date))
      .y0((d) => yStackedArea(d[0]))
      .y1((d) => yStackedArea(d[1]));

    const stackedAreaChart = svg.select('#stackedAreaChart')
      .attr('transform', `translate(${MARGINS.left},
        ${MARGINS.top + _.sum([yDimensions.pacingLineChart, yDimensions.divider1])})`);

    const { keys: childIds, dataWithICOSeparated, dataWithICOMerged } = stacked;

    const icoIds = _.keys(cloneToOrig);
    const nonICOKeys = _.reject(childIds, (c) => icoIds.includes(c));
    /*
    for the purposes of lining up legend and area, we need to reverse because by default
    d3 starts first area from the bottom
    */
    stackGeneratorICOMerged.keys(_.reverse([...nonICOKeys]));
    stackGeneratorICOSeparated.keys(_.reverse([...childIds]));

    const stackedDataWithICOMerged: Array<Series<StackingDatum, string>> = stackGeneratorICOMerged(dataWithICOMerged);

    const stackedDataWithICOSeparated: Array<Series<StackingDatum, string>> = stackGeneratorICOSeparated(dataWithICOSeparated);

    yStackedArea.domain([0, 100]); // percentages

    colorScale.domain(nonICOKeys);

    const layers = stackedAreaChart.selectAll('.layer')
      .data(stackedDataWithICOMerged)
      .enter().append('g')
      .attr('class', 'layer');

    layers.append('path')
      .attr('fill', (d) => colorScale(_.get(cloneToOrig, d.key, d.key)))
      .attr('d', areaGenerator);

    stackedAreaChart.selectAll('.ICO-layer')
      .data(_.filter(stackedDataWithICOSeparated, (d) => _.has(cloneToOrig, d.key)))
      .enter().append('g')
      .attr('class', 'ICO-layer')
      .append('path')
      .attr('fill', 'url(#diagonalHatch)')
      .attr('d', areaGenerator);

    stackedAreaChart.append('g')
      .attr('class', 'axis axis--y')
      .call(
        axisLeft(yStackedArea)
          .tickValues([25, 50, 75])
          .tickFormat((d: number) => numeral(d / 100).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)),
      );

    createAxisLabel(`Delivery by ${strategy.strategyType.dsp === DSP.TTD.id ? 'Ad Group' : 'Line Item'}`, stackedAreaChart, yRanges.stackedAreaChart);

    /*
    Performance Line Chart
    */
    const performanceLabel = getPerformanceLabel(strategyGoalType, isCustomGoal);
    const performanceFormat = getPerformanceFormat(strategyGoalType, metrics);

    const performanceLineChart = svg.select('#performanceLineChart')
      .attr('transform', `translate(${MARGINS.left},
        ${MARGINS.top + _.sum([yDimensions.pacingLineChart,
    yDimensions.divider1,
    yDimensions.stackedAreaChart,
    yDimensions.divider2])})`);

    const [yMin, yMax] = extent(_.flatMap(cumulativePerformanceData, (d) => [d[goalTypeKey], d[estimatedKPIKey] ?? 0]));
    const yDiff = yMax - yMin;
    const yPadding = yDiff * 0.2;
    // y-axis
    const yPerformance = scaleLinear()
      .domain([_.max([yMin - yPadding, 0]), yMax + yPadding])
      .range(yRanges.performanceLineChart)
      .clamp(true);

    const performanceYAxis = performanceLineChart.append('g')
      .attr('class', 'axis axis--y')
      .call(axisLeft(yPerformance)
        .tickValues([
          yPerformance.domain()[0], // min
          yMin + (0.5 * yDiff), // middle
          yPerformance.domain()[1], // max
        ])
        .tickFormat((n: number) => numeral(n).format(performanceFormat)));

    // y-axis unit labels
    performanceYAxis.append('text')
      .text(yScaleUnits.performance)
      .attr('class', 'unit-label')
      .attr('dx', -5)
      .attr('dy', -10);

    // performance area (difference b/w unoptimized & optimized line)
    performanceLineChart.append('path')
      .attr('id', 'performance-area')
      // only show area after copilotAttachmentTime
      .datum(_.filter(cumulativePerformanceData, (datum) => datum.date.isSameOrAfter(flightAttachmentTime)))
      .attr('d', area<PerformanceDatum>()
        .x((d) => x(d.date))
        .y0((d) => (d.date.isSame(flightAttachmentTime) ? yPerformance(d[goalTypeKey]) : yPerformance(d[estimatedKPIKey] ?? 0)))
        .y1((d) => yPerformance(d[goalTypeKey]))
        .defined((d) => !_.isNil(d[goalTypeKey] ?? 0)));

    // performance line
    performanceLineChart.append('path')
      .attr('id', 'performance-line')
      .datum(cumulativePerformanceData)
      .attr('d', line<PerformanceDatum>()
        .x((d) => x(d.date))
        .y((d) => yPerformance(d[goalTypeKey]))
        .defined((d) => !(_.isNil(d[goalTypeKey]))));

    // unoptimized performance line
    performanceLineChart.append('path')
      .attr('id', 'unoptimized-performance-line')
      // only show estimated line after copilotAttachmentTime
      .datum(_.filter(cumulativePerformanceData, (datum) => datum.date.isSameOrAfter(flightAttachmentTime)))
      .attr('d', line<PerformanceDatum>()
        .x((d) => x(d.date))
        .y((d) => (d.date.isSame(flightAttachmentTime) ? yPerformance(d[goalTypeKey]) : yPerformance(d[estimatedKPIKey] ?? 0)))
        .defined((d) => !(_.isNil(_.get(d, goalTypeKey)))));

    createAxisLabel(performanceLabel, performanceLineChart, yRanges.performanceLineChart);

    createFlightAttachmentMarker(svg, yDimensions, x, flightAttachmentTime, moment(negativeBudgetDate));

    initHoverBehavior(
      container,
      svg,
      stackedDataWithICOMerged,
      stackedDataWithICOSeparated,
      origToClone,
      cumulativePerformanceData,
      pacingData,
      x,
      width,
      height,
      goalTypeKey,
      strategyGoalType,
      estimatedKPIKey,
      accessorUnit,
      stackedAreaChart,
      yPacingLine,
      yPerformance,
      colorScale,
      validPacingBounds,
      flightAttachmentTime,
      metrics,
    );

    buildLegend(
      budgetType,
      yDimensions,
      stackedDataWithICOSeparated,
      origToClone,
      cloneToOrig,
      colorScale,
      performanceLabel,
      totalAccumulatedBudget,
      cumulativePacingChildrenData,
      childExtIdToSettings,
    );

    createHorizontalLine(
      container,
      _.sum([(container.node() as HTMLDivElement).offsetTop,
        MARGINS.top,
        yDimensions.pacingLineChart,
        yDimensions.divider1 / 2]),
    );
    createHorizontalLine(
      container,
      _.sum([(container.node() as HTMLDivElement).offsetTop,
        MARGINS.top,
        yDimensions.pacingLineChart,
        yDimensions.divider1,
        yDimensions.stackedAreaChart,
        yDimensions.divider2 / 2]),
    );
  }
};

export default (props: BudgetOptimizationVizProps) => {
  const containerId = 'budget-optimization-viz';
  const [svg, setSvg] = useState<GenericSelection | null>(null);
  const [dimensions, setDimensions] = useState<{ height: number, width: number }>({ height: 0, width: 0 });

  const { data, strategy, env, stratData } = props;

  useMount(() => {
    const [width, height] = getChartDimensions(`#${containerId} #chart`);
    const mySvg = buildLayers(`#${containerId} #chart`, width, height);
    setSvg(mySvg);
    setDimensions({ width, height });
  });

  useEffect(() => {
    if (svg && dimensions) {
      buildViz(select(`#${containerId}`), svg, dimensions.width, dimensions.height, data, strategy, stratData);
    }
  }, [svg, dimensions, data, strategy, stratData]);

  const { headerMetrics: { metrics: topMetrics, metricsConfig }, metaData } = data;

  const pacingCopy = getPacingCopy(metaData.dailyParentBudgetInflationRatio);

  const metricsConfigBasedOnEnv = env === EnvironmentVariables.demo
    ? _.pickBy(metricsConfig, (o) => o.value !== 'margin')
    : metricsConfig;

  return (
    <div id="budget-optimization-viz-wrapper">
      <Statbox metrics={topMetrics} metricsConfig={metricsConfigBasedOnEnv} />
      <div id={containerId}>
        <div id="chart">
          <svg>
            <g id="budget-delivered-group" />
            <g id="pacingLineChart" />
            <g id="stackedAreaChart">
              <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="8" height="8">
                <path
                  d="M-1,1 l2,-2
                  M0,8 l8,-8
                  M6,10 l4,-4"
                />
              </pattern>
            </g>
            <g id="performanceLineChart" />
          </svg>
        </div>
        <div id="legend">
          <div id="pacing">
            <div><div className="line" /><span className="label" /></div>
            <div><div className="line" />Pacing target</div>
            <p className="helper-text">{pacingCopy}</p>
          </div>
          <div id="budget-allocation">
            <table>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th>Total Budget Allocation</th>
                  <th className="label" colSpan={2} />
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
          <div id="performance">
            <div><div className="line" />
              <p>Cumulative&nbsp;<span className="kpi" /></p>
            </div>
            <div>
              <div className="line" /><p>Estimated Cumulative&nbsp;<span className="kpi" />&nbsp;without Copilot</p>
            </div>
            <p className="helper-text">
              Predicted Cumulative&nbsp;<span className="kpi" />&nbsp;in absence of a Copilot model.&nbsp;
              <a href={BUDGET_OPT_ANALYTICS_WIKI} target="_blank" rel="noopener noreferrer">Read more.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
