/* eslint-disable react/static-property-placement */
import _ from 'lodash';
import fp from 'lodash/fp';
import React, { CSSProperties } from 'react';
import { Dropdown } from 'buildingBlocks';

type Value<T> = T | Array<T>;

export type OnSearchChange = (...args: any[]) => any;

type NoSearch = {
  searchType: 'none',
};

type APISearch = {
  searchType: 'api',
  onSearchChange: OnSearchChange,
  /**
   * Debounce option for the onSearchChange Function:
   * - timer in ms
   * - option see https://lodash.com/docs/4.17.10#debounce for possible options
   */
  debounce?: {
    timer: number,
    option?: Object,
  },
};

type LocalSearch = {
  searchType: 'local',
  // If no function provided, will use default semantic search behavior
  onSearchChange?: OnSearchChange,
};

export type ObjectDropdownSearch = NoSearch | APISearch | LocalSearch;

export type Props<T> = {
  onClick?: Function,
  /** Function to call when dropdown selection changes. */
  onChange?: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  /** Array of options for the dropdown. */
  options: Array<T>,
  // eslint-disable-next-line react/no-unused-prop-types
  /** Initial selected value of the Dropdown. */
  value?: Value<T>,
  // eslint-disable-next-line react/no-unused-prop-types
  /**
   * Function used to "key" each object in `options`. The result of this
   * function will, for each object, be used for both the option key and the
   * label displayed to the user, and must be unique.
   */
  keyFn?: (obj: T) => string,
  // eslint-disable-next-line react/no-unused-prop-types
  displayFn?: (obj: T) => any,
  /**
   * Disallow removing element while hitting backspace. Ignored if `multiple`
   * is not true.
   */
  disableRemoveItem?: boolean,
  /** Whether to allow multiple selections. */
  multiple?: boolean,
  /** The Form input synced with and managed by this dropdown. */
  input?: {
    value: T,
    name: string,
    onChange: Function,
  },
  name?: string,
  search?: ObjectDropdownSearch,
  style?: CSSProperties,
  width?: number,
  text?: string,
  selection?: boolean,
  fluid?: boolean,
  placeholder?: string,
  disabled?: boolean,
  loading?: boolean,
  scrolling?: boolean,
  selectOnBlur?: boolean,
  renderLabel?: Function,
  closeOnChange?: boolean,
  className?: string,
};

type KeyedOptions<T> = { [key: string]: T };
type Option = { text: string, value: string, content: string | JSX.Element };

type State<T> = {
  disableRemoveItem: boolean,
  keyedOptions: KeyedOptions<T>,
  options: Array<Option>,
  passthroughProps: {
    value: string | Array<string>,
  },

  // onSearchChange will only be set when it's an api search, otherwise
  // search: true => use semantic default search
  // search: Function => customized local search
  // `search: undefined` treated as `search: false` => no search feature
  semanticSearchProps: { search?: Function | boolean | null, onSearchChange?: OnSearchChange },
};

class ObjectDropdown<T> extends React.Component<Props<T>, State<T>> {
  static getSemanticSearchProps(search: ObjectDropdownSearch) {
    switch (search.searchType) {
      case 'api': {
        const { onSearchChange, debounce } = search;
        return {
          search: true,
          onSearchChange: (!debounce
            ? onSearchChange
            : _.debounce(onSearchChange, debounce.timer, debounce.option)),
        };
      }
      case 'local': {
        const { onSearchChange } = search;
        return { search: onSearchChange || true };
      }
      case 'none':
        return {};
      default:
        throw new Error(`Unknown search type ${search}`);
    }
  }

  static createOptionsArray<T>(keyedOptions: KeyedOptions<T>): Array<Option> {
    return fp.flow(
      fp.toPairs,
      fp.map(([key, value]) => ({ text: key, value: key, content: value.content })),
    )(keyedOptions);
  }

  static createState<T>({
    options, value, keyFn, displayFn = _.identity, multiple, disableRemoveItem, search, ...rest
  }: Props<T>): State<T> {
    const formattedOptions = _.map(options, displayFn);
    const keyedOptions = _.keyBy(formattedOptions, keyFn);
    const passthroughProps: {
      value: string | Array<string>,
      name?: string
    } = { ..._.omit(rest, ['onChange', 'input', 'meta']), value: '' };
    const valueProps = value || (rest.input && rest.input.value);
    const nameProps = rest.name || (rest.input && rest.input.name);

    if (nameProps) {
      passthroughProps.name = nameProps;
    }
    if (valueProps) {
      if (multiple) {
        if (Array.isArray(valueProps)) {
          passthroughProps.value = _.map(valueProps, keyFn);
        } else {
          passthroughProps.value = keyFn(valueProps);
        }
      } else {
        // In case of Redux form, We get the value as an array(in form of input.value) for ObjectDropdown case.
        // And in case of normal form, value is retrieved as 'value' itself without an array.
        const singleValue = Array.isArray(valueProps) ? valueProps[0] : valueProps;
        if (singleValue) {
          passthroughProps.value = keyFn(singleValue);
        }
      }
    }
    return {
      disableRemoveItem,
      keyedOptions,
      options: ObjectDropdown.createOptionsArray(keyedOptions),
      semanticSearchProps: ObjectDropdown.getSemanticSearchProps(search),
      passthroughProps,
    };
  }

  static defaultProps = {
    search: {
      searchType: 'none',
    },
  };

  constructor(props: Props<T>) {
    super(props);
    this.state = ObjectDropdown.createState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    this.setState(ObjectDropdown.createState(nextProps));
  }

  onChange(data: { value: Value<string> }) {
    if (this.state.disableRemoveItem) {
      // Disallow removing element while hitting backspace when disableRemoveItem is flagged
      if (this.props.multiple && Array.isArray(data.value)
        && data.value && this.state.passthroughProps.value
        && data.value.length < this.state.passthroughProps.value.length) {
        return;
      } if (data === undefined) {
        return;
      }
    }

    let obj;

    if (Array.isArray(data.value)) {
      obj = _.map(data.value, (id) => this.state.keyedOptions[id]);
    } else {
      obj = this.state.keyedOptions[data.value];
    }

    if (this.props.onChange) {
      this.props.onChange(obj);
    }
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(obj);
    }
  }

  render() {
    return (
      // @ts-ignore dropdown really does have multiple
      <Dropdown
        multiple={this.props.multiple}
        options={this.state.options}
        onChange={(_e: any, data: any) => this.onChange(data)}
        {...this.state.semanticSearchProps}
        {...this.state.passthroughProps}
      />
    );
  }
}

export default ObjectDropdown;
