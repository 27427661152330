/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { nest, extent, ascending } from 'd3';
import moment from 'moment';
import { createDate, createIsoDate } from 'charts/utils';
import METRICS from 'containers/StrategyAnalytics/constants/metricsConstants';
import { GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { isCampaignLevelOptimization } from 'containers/StrategyAnalytics/utils/tabUtils';
import { AggregatedAnalyticsData, StrategyGoalAnalyticsData, StrategyGoalAnalyticsGoalType, StrategyGoalAnalyticsMetadataType } from 'containers/StrategyAnalytics/types';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { LEGACY_PREFIX } from 'containers/StrategyAnalytics/constants/strategyAnalyticsConstants';
import { includeAdditionalMetrics } from 'containers/StrategyAnalytics/utils/metricsUtils';
import { getActiveStrategyGoalsInPriorityOrder } from 'utils';
import { safeDivision } from 'utils/calculations';
import { StrategyGoalDB, Strategy, MetricsConstant } from 'utils/types';
import { BudgetTypeMapping } from './constants';
import {
  BudgetType,
  TransformedData,
  StackingDataConfig,
  Data,
  HeaderMetrics,
  ChildData,
  StackingDatum,
  PacingDatum,
  CumulativeChildPacing,
  ChildAnalyticsDatum,
  ChildSettings,
  IntelligentChildObjectMap,
  PacingState,
  DataVizData,
  PerformanceData,
  BudgetOptDataPacingDatum,
} from './types';
import { getEstimatedKPIKey } from './helpers';

const noDeliveryForLastDatum = <D>(data: Array<D>,
  budgetType: BudgetTypeMapping) => _.get(_.last(data), budgetType, 0) === 0;

const getFirstDateNoDeliveryStreak = (data: Array<StrategyGoalAnalyticsData>, budgetType: BudgetTypeMapping) => {
  // count # data points from end of parentData where delivery === 0
  const nDaysNoDeliveryStreak = _.size(_.takeRightWhile(data, (datum) => datum[budgetType] === 0));
  const idxFirstDatumNoDelivery = _.size(data) - nDaysNoDeliveryStreak;
  return _.get(data, `${idxFirstDatumNoDelivery}.date`);
};

export const filterData = (allData: Data) => {
  const { hierarchy: { parentSettings: { budgetType } }, parentData, childData } = allData;
  const parentDailyData = _.get(parentData, 'data', []);
  const parentCumData = _.get(parentData, 'cumData', []);
  // 1: if last parent datum did not contribute to delivery, then filter last datum from all data arrays
  const dateOfNoDeliveryStreakStart = getFirstDateNoDeliveryStreak(parentDailyData, BudgetTypeMapping[budgetType]);

  const rejectDataInNoDeliveryStreak = (d) => {
    // if there is no "no delivery" streak, then nothing should be rejected
    if (!dateOfNoDeliveryStreakStart) {
      return false;
    }
    return d.date >= dateOfNoDeliveryStreakStart;
  };

  const filteredParentData = {
    ...parentData,
    cumData: _.reject(parentCumData, rejectDataInNoDeliveryStreak),
    data: _.reject(parentDailyData, rejectDataInNoDeliveryStreak),
    pacingBounds: _.reject(_.get(parentData, 'pacingBounds', []), rejectDataInNoDeliveryStreak),
  };
  const filteredChildData = _.mapValues(childData, ({ cumData, data }) => ({
    cumData: _.reject(cumData, rejectDataInNoDeliveryStreak),
    data: _.reject(data, rejectDataInNoDeliveryStreak),
  }));

  return {
    ...allData,
    parentData: filteredParentData,
    // 2. child data is irrelevant if the item has not contributed to delivery within date range
    // (notice our use of cumData here)
    childData: _.omitBy(
      filteredChildData,
      ({ cumData }) => noDeliveryForLastDatum<ChildAnalyticsDatum>(cumData, BudgetTypeMapping[budgetType]),
    ),
  };
};

export const getPercentagesAndTotal = (
  obj: { [key: string]: number },
  total: number,
  allPossibleChildIds: Array<string>,
  nonICOKeys: Array<string>,
  origToClone: IntelligentChildObjectMap,
  separateClones: boolean,
) => {
  // we should explicitly assign a child's value to 0 if it does not appear for a given date's data
  let entries;

  if (separateClones) {
    entries = _.fromPairs(
      _.map(allPossibleChildIds, (childId) => {
        const percentage = safeDivision(_.get(obj, childId, 0), total) * 100;
        return [childId, percentage];
      }),
    );
  } else {
    entries = _.fromPairs(
      _.map(nonICOKeys, (childId) => {
        const childAmt = _.get(obj, childId, 0);
        const cloneAmt = _.get(obj, origToClone[childId], 0);
        const percentage = safeDivision(_.sum([childAmt, cloneAmt]), total) * 100;
        return [childId, percentage];
      }),
    );
  }

  entries.total = total;
  return entries;
};

// keys should be sorted such that ICO keys come directly after their original copies
export const customKeySort = (nonICOKeys: Array<string>, origToClone: IntelligentChildObjectMap) => {
  const groupedByClonesSortedKey = [];
  _.forEach(nonICOKeys, (k) => {
    groupedByClonesSortedKey.push(k);
    const clone = _.get(origToClone, k);
    if (clone) {
      groupedByClonesSortedKey.push(clone);
    }
  });
  return groupedByClonesSortedKey;
};

const generateStackingDataArray = (
  combinedData,
  parentData: Array<StrategyGoalAnalyticsData>,
  budgetType: BudgetTypeMapping,
  keys: Array<string>,
  nonICOKeys: Array<string>,
  origToClone: IntelligentChildObjectMap,
  separateClones: boolean,
) => (_.map(combinedData, (d) => {
  const parentObj = _.find(parentData, (pD) => _.isEqual(pD.date, d.key));
  return ({
    date: createDate(d.key),
    ...getPercentagesAndTotal(d.value, _.get(parentObj, budgetType, 0), keys, nonICOKeys, origToClone, separateClones),
  });
}) as Array<StackingDatum>);
/*
Transform data into a form that can be passed to d3.stack() function
*/
export const combineDataForStacking = (
  budgetType: BudgetTypeMapping,
  childData: ChildData,
  parentData: Array<StrategyGoalAnalyticsData>,
  childExtIdToSettings: ChildSettings,
  origToClone: IntelligentChildObjectMap,
): StackingDataConfig => {
  const flatChildData = _.flatMap(childData, 'data');
  const combinedData = nest<ChildAnalyticsDatum, _.Dictionary<number>>()
    .key((d) => d.date)
    .sortKeys(ascending)
    .rollup((vals) => (_.fromPairs(
      _.map(vals, (v) => ([v.childExtId, v[budgetType]])),
    )))
    .entries(flatChildData);

  /*
    we are getting keys from the data itself, rather than from the childExtIdToSettings
    metadata because it's possible there are some child objects in the metadata that do
    not show up at all in the data
  */
  const keys = _.sortBy(
    _.uniq(_.flatMap(combinedData, ({ value }) => _.keys(value))),
    (id) => _.get(childExtIdToSettings[id], 'name'),
  ) as Array<string>;

  const nonICOKeys = _.reject(keys, (k) => _.includes(_.values(origToClone), k));
  const customSortedKeys = customKeySort(nonICOKeys, origToClone);

  // in order to generate stacked area graph properly, we need data combined in both ways
  const dataWithICOSeparated = generateStackingDataArray(combinedData, parentData, budgetType, keys, nonICOKeys, origToClone, true);
  // merge datapoints for ILI + Original
  const dataWithICOMerged = generateStackingDataArray(combinedData, parentData, budgetType, keys, nonICOKeys, origToClone, false);

  return {
    dataWithICOSeparated,
    dataWithICOMerged,
    keys: customSortedKeys,
  };
};

// revenue from datum already calculated correctly for revenue type strategies
const getBudgetTypeMetricAndMoment = (parentData: Array<StrategyGoalAnalyticsData>, budgetType: BudgetTypeMapping) => _.map(
  parentData,
  (d) => ({
    [budgetType]: d[budgetType],
    date: createDate(d.date),
  }),
);

const getCumulativeChildPacingData = (childData: ChildData, budgetType: BudgetTypeMapping) => _.mapValues(childData, ({ cumData }) => ({
  cumData: _.map(cumData, (c) => ({
    [budgetType]: c[budgetType],
    date: createDate(c.date),
  })),
  totalAccumulatedBudget: _.get(_.last(cumData), budgetType, 0),
}));

// unoptimized_goal_type comes from budgetOptPacing endpoint
const getCumulativePerformanceData = (cumParentData: Array<StrategyGoalAnalyticsData>, budgetOptPacingData: any, estimatedKPIKey: string) => _.map(
  cumParentData,
  (datum) => {
    // add estimatedKpiCumulative under estimatedKPIKey to get non copilot metrics
    const budgetOptDatum = _.find(budgetOptPacingData, ({ date }) => moment(date).isSame(datum.date, 'day'));
    return _.assign({}, { ...datum, [estimatedKPIKey]: _.get(budgetOptDatum, 'estimatedKpiCumulative') }, { date: createDate(datum.date) });
  },
);

const pacingConfigMap = {
  [PacingState.active]: METRICS.ratePercentage.currentPacing,
  [PacingState.paused]: METRICS.ratePercentage.currentPacingPaused,
  [PacingState.final]: METRICS.ratePercentage.finalPacing,
};

const getGoalMetricConfigData = (strategyGoals: Array<string>, primaryStratGoalType: string, datumSource: MetricsConstant, hasCustomGoal: boolean) => _.map(strategyGoals, (gT) => {
  const datumKey = hasCustomGoal ? primaryStratGoalType : gT;
  const datum = _.get(datumSource, `ratePercentage.${datumKey}`, null) || _.get(datumSource, `aggregator.${datumKey}`, null);
  // use actual custom goal name i.e: weightedCpm
  return [datumKey, datum];
});

const getGoalMetricData = (strategyGoals: Array<string>, primaryStratGoalType: string, datumSource: StrategyGoalAnalyticsData, hasCustomGoal: boolean, goalTypeKey: string) => _.map(strategyGoals, (gT) => {
  const goalType = hasCustomGoal ? primaryStratGoalType : gT;
  // use the secondary goalType as the key when the goalType is a secondary goal
  const isSecondaryGoal = _.isEqual(goalType, GOAL_TYPES.ivrMeasured.value);
  // goalTypeKey ensures we're grabbing the correct value for each StrategyGoalAnalyticsData
  const datum = _.get(datumSource, isSecondaryGoal ? goalType : goalTypeKey, null);
  // use actual custom goal name i.e: weightedCpm
  return [goalType, datum];
});

export const getHeaderMetrics = (
  parentCumulativeData: Array<StrategyGoalAnalyticsData>,
  currentPacing: number,
  strategyGoalTypes: Array<string>,
  pacingState: PacingState = PacingState.active,
  strategy: Strategy,
  eventBudgetDelivered: number,
  pacingData: Array<BudgetOptDataPacingDatum> = [],
  strategyData: AggregatedAnalyticsData,
  goalTypeKey: string,
): HeaderMetrics => {
  const latestCumulativeDatum = _.last(parentCumulativeData);
  const stratTypeId = _.get(strategy.strategyType, 'id');
  const upLiftFinalValue = _.get(_.last(pacingData), 'estimatedUplift');
  const primaryStratGoal = _.get(strategyData, 'metadata.goal');
  const primaryStratGoalType = _.camelCase(primaryStratGoal.name);
  const hasCustomGoal = !primaryStratGoal.isSystemGoal || _.isEqual(GOAL_TYPES.impactOutcome.value, primaryStratGoalType);
  const baseMetrics = {
    revenue: _.get(latestCumulativeDatum, BudgetTypeMapping.amount, null),
    impressions: _.get(latestCumulativeDatum, BudgetTypeMapping.imps, null),
    ...(_.fromPairs(getGoalMetricData(strategyGoalTypes, primaryStratGoalType, latestCumulativeDatum, hasCustomGoal, goalTypeKey))),
    ...(isCampaignLevelOptimization(stratTypeId) && { upLift: upLiftFinalValue }),
    margin: _.get(latestCumulativeDatum, 'margin'),
  };
  const showEventBudgetDeliveredInsteadOfPacing = eventBudgetDelivered && (_.isNil(currentPacing)) && (pacingState !== PacingState.final);
  let metrics;
  if (showEventBudgetDeliveredInsteadOfPacing) {
    metrics = { ...baseMetrics, eventBudgetDelivered };
  } else {
    metrics = {
      ...baseMetrics,
      currentPacing: (pacingState === PacingState.paused) ? PacingState.paused : currentPacing,
    };
  }
  const baseMetricToUse = includeAdditionalMetrics(primaryStratGoal, _.head(_.get(strategyData, 'dailyData')));
  const baseMetricsConfig = {
    revenue: baseMetricToUse.aggregator.revenue,
    impressions: baseMetricToUse.aggregator.impressions,
    ...(_.fromPairs(getGoalMetricConfigData(strategyGoalTypes, primaryStratGoalType, baseMetricToUse, hasCustomGoal))),
    ...(isCampaignLevelOptimization(stratTypeId) && { upLift: baseMetricToUse.ratePercentage.upLift }),
  };
  let metricsConfig;
  if (showEventBudgetDeliveredInsteadOfPacing) {
    metricsConfig = {
      ...baseMetricsConfig,
      eventBudgetDelivered: baseMetricToUse.ratePercentage.eventBudgetDelivered,
    };
  } else {
    metricsConfig = {
      ...baseMetricsConfig,
      currentPacing: pacingConfigMap[pacingState],
    };
  }
  return {
    metrics,
    metricsConfig,
  };
};

const getPerformanceUnit = (goalValueType: string, currencyCode: string): string | null => {
  switch (goalValueType) {
    case (GOAL_VALUE_TYPE.CURRENCY):
      return currencyCode;
    case (GOAL_VALUE_TYPE.PERCENTAGE):
      return '%';
    default:
      return null;
  }
};

const getYScaleUnits = (strategyGoalType: string, budgetType: BudgetType, currencyCode: string) => {
  const goalValueType = _.get(METRICS.ratePercentage[strategyGoalType], 'valueType', GOAL_VALUE_TYPE.NONE);

  const performanceUnit = getPerformanceUnit(goalValueType, currencyCode);

  return {
    pacing: (budgetType === 'amount') ? currencyCode : '%',
    performance: performanceUnit,
  };
};

export const getStrategyGoalTypes = (strategyGoals: Array<StrategyGoalDB>) => _.map(
  getActiveStrategyGoalsInPriorityOrder(strategyGoals),
  (d) => _.camelCase(d.type),
);

const getGoalTypeKey = (goal: StrategyGoalAnalyticsGoalType) => {
  const customKey = `goal1_${goal.name}`;
  return _.camelCase(customKey);
};

const getEarliestAttachmentDate = (metadataAttachments: Array<{}>) => _.chain(metadataAttachments)
  .sortBy('parentAttachDate')
  .head()
  .get('parentAttachDate')
  .value();

const getMetadataFromBudgetSettings = (metadata: StrategyGoalAnalyticsMetadataType, isCrossPlatform: boolean) => {
  const singleBudgetSetting = _.head(metadata.budgetSettings);
  const { parentSettings: { budgetType, currencyCode }, parentExtId } = singleBudgetSetting;
  let childExtIdToSettings = _.reduce(metadata.budgetSettings, (acc, config) => {
    _.merge(acc, config.childExtIdToSettings);
    return acc;
  }, {});
  childExtIdToSettings = _.mapKeys(childExtIdToSettings, (_v, k) => _.toLower(k));
  return {
    hierarchy: {
      parentSettings: { budgetType, currencyCode, parentExtId: isCrossPlatform ? 'multi_dsp' : parentExtId },
      childExtIdToSettings,
    },
  };
};

const getKeyToUse = (key: string) => (_.isEqual(key, 'cumData') ? 'cumData' : 'data');

const getGroupedChildData = (config: Array<StrategyGoalAnalyticsData>, key: string) => {
  // key is only ever cumData or dailyData
  const groupKey = getKeyToUse(key);
  const groupedChildData = _.groupBy(config, 'childExtId');
  const childData = _.mapValues(groupedChildData, (vals) => ({ [groupKey]: vals }));
  return childData;
};

const extractMetadata = (strategyAnalyticsMetadata: StrategyGoalAnalyticsMetadataType, strategy: Strategy) => {
  const flightAttachmentTime = getEarliestAttachmentDate(strategyAnalyticsMetadata.attachments);
  const goalName = _.get(strategyAnalyticsMetadata, 'goal.name');
  const primaryStrategyGoal = _.startsWith(goalName, LEGACY_PREFIX)
    ? _.replace(goalName, LEGACY_PREFIX, '')
    : goalName;
  const isCrossPlatform = isCrossPlatformStrategyType(strategy.strategyType.id);
  const origToClone = strategyAnalyticsMetadata.copilot.origToClone;
  return {
    flightAttachmentTime,
    primaryStrategyGoal: _.camelCase(primaryStrategyGoal),
    origToClone,
    ...getMetadataFromBudgetSettings(strategyAnalyticsMetadata, isCrossPlatform),
  };
};

const convertDateToIso = (data: Array<StrategyGoalAnalyticsData>) => _.map(data, (datum: StrategyGoalAnalyticsData) => ({ ...datum, date: createIsoDate(datum.date) }));

const formatParentData = (stratData: AggregatedAnalyticsData) => {
  const data = _.mapKeys(_.omit(stratData, 'metadata'), (_vals, key) => (_.isEqual(key, 'dailyData') ? 'data' : key)) as Omit<AggregatedAnalyticsData, 'metadata'>;
  return _.mapValues(data, (vals) => convertDateToIso(vals));
};

const buildDataFromStratGoalAnalytics = (strategy: Strategy, strategyLevelAnalytics: AggregatedAnalyticsData, childLevelAnalyticsData: AggregatedAnalyticsData, pacingData: any) => {
  const childData = _.reduce(childLevelAnalyticsData, (acc, config: Array<StrategyGoalAnalyticsData>, key: string) => {
    if (!_.isEqual(key, 'metadata')) {
      _.merge(acc, getGroupedChildData(convertDateToIso(config), key));
    }
    return acc;
  }, {});
  return {
    ...extractMetadata(childLevelAnalyticsData.metadata, strategy),
    childData,
    parentData: {
      ...formatParentData(strategyLevelAnalytics),
      pacingBounds: pacingData,
    },
  };
};

export const transformData = (dataVizData: DataVizData, strategy: Strategy): TransformedData => {
  const budgetOptPacingData = _.get(dataVizData, 'budgetOptData.pacingData');
  const formattedBudgetOptPacingData = _.map(budgetOptPacingData, (datum) => ({ ...datum, date: createIsoDate(datum.date) }));
  const filteredData = buildDataFromStratGoalAnalytics(strategy, dataVizData.stratData, dataVizData.childData, formattedBudgetOptPacingData);
  /*
  because desnakify is not recursive, some of the childIds are getting camelcased and some
  are not.
  Below, we make sure keys we rely on don't get camelCased.
  */
  const transformedData = {
    ...filteredData,
    childData: _.mapKeys(filteredData.childData, (_v, k) => _.toLower(k)),
    origToClone: _.mapKeys(filteredData.origToClone, (_v, k) => _.toLower(k)),
  } as unknown as Data;

  const filteredTransformedData = filterData(transformedData);
  const { hierarchy, childData, parentData, primaryStrategyGoal, flightAttachmentTime, origToClone = {} } = filteredTransformedData;
  const { parentSettings: { budgetType, currencyCode }, childExtIdToSettings } = hierarchy;

  const cloneToOrig = _.invert(origToClone);
  const estimatedKPIKey = getEstimatedKPIKey(primaryStrategyGoal);
  const parentCumData = _.get(parentData, 'cumData', []);
  const parentDailyData = _.get(parentData, 'data', []);
  const cumulativePerformanceData = getCumulativePerformanceData(parentCumData, formattedBudgetOptPacingData, estimatedKPIKey);

  const dateRange = extent(_.map(cumulativePerformanceData, (d) => createDate(d.date)));
  // converts amount -> revenue when trying to access datum
  const mappedBudgetType = BudgetTypeMapping[budgetType];
  const strategyGoalTypes = getStrategyGoalTypes(strategy.strategyGoals);
  const goalTypeKey = getGoalTypeKey(dataVizData.stratData.metadata.goal);
  const stacked = combineDataForStacking(mappedBudgetType, childData, parentDailyData, childExtIdToSettings, origToClone);
  const cumulativePacing = getBudgetTypeMetricAndMoment(parentCumData, mappedBudgetType);
  const negativeBudgetDate = _.get(dataVizData, 'budgetOptData.negativeBudgetDate') ? new Date(_.get(dataVizData, 'budgetOptData.negativeBudgetDate')) : null;
  const pacingData = _.get(parentData, 'pacingBounds');
  // pacingBounds might have more data than parentData.data causing pacingBounds to extend past chart width
  const allPacingBounds = _.map(pacingData, (b) => ({ ...b, date: createDate(b.date) }));
  const pacingBounds = _.filter(allPacingBounds, ({ date }) => date.isBetween(_.head(dateRange), _.last(dateRange), 'day', '[]'));
  return {
    // used for Performance chart
    performance: cumulativePerformanceData as unknown as PerformanceData,
    totalAccumulatedBudget: _.get(_.last(cumulativePacing), mappedBudgetType, 0) as number,
    cumulativePacingChildren: getCumulativeChildPacingData(childData, mappedBudgetType) as CumulativeChildPacing,
    // used for Stacked Area/Budget Allocation chart
    stacked,
    metaData: {
      dateRange,
      // keep budgetType as budgetTypeDb to retain original monitoring tab functionality/mappings in BOV
      budgetType,
      // used to access METRICS constant
      strategyGoalType: primaryStrategyGoal,
      // used to access datum from strategyGoalAnalytics
      goalTypeKey,
      // we use estimatedKPIKey for Performance chart to compare copilot vs noncopilot result
      // -> we use this to calculate estimated_kpi_cumulative which we get already from new endpoint
      estimatedKPIKey,
      yScaleUnits: getYScaleUnits(primaryStrategyGoal, budgetType, currencyCode),
      childExtIdToSettings,
      flightAttachmentTime: createDate(flightAttachmentTime).startOf('day'),
      // we use this to getPacingCopy to return the text for pacingCopy
      dailyParentBudgetInflationRatio: _.get(dataVizData, 'budgetOptData.dailyParentBudgetInflationRatio'),
      cloneToOrig,
      origToClone,
    },
    // pacing and pacingBounds used for Pacing chart
    pacing: getBudgetTypeMetricAndMoment(parentDailyData, mappedBudgetType) as Array<PacingDatum>,
    pacingBounds,
    headerMetrics: getHeaderMetrics(
      parentCumData,
      dataVizData.budgetOptData?.currentPacing,
      strategyGoalTypes,
      PacingState[dataVizData.budgetOptData?.pacingState],
      strategy,
      dataVizData.budgetOptData?.eventBudgetDelivered,
      pacingBounds,
      dataVizData.stratData,
      goalTypeKey,
    ),
    negativeBudgetDate,
  };
};
