import _ from 'lodash';
import React from 'react';
import { Input, Label } from 'buildingBlocks';
import { shiftDecimalSeparator } from 'utils/formattingUtils';

const getValueBasedOnShift = (value: number | string, shift: number, precision: number): number => {
  let res;

  // if precision is set we need to round it on it final displaying form
  // => if we shift to the left, we need to round it before the shifting
  // => if we shift to the right, we need to round it after the shifting
  if (_.isInteger(precision)) {
    if (shift < 0) {
      res = shiftDecimalSeparator(_.round(Number(value), precision), shift);
    } else {
      res = _.round(shiftDecimalSeparator(value, shift), precision);
    }
  } else {
    res = shiftDecimalSeparator(value, shift);
  }

  // if the result of the shifting didn't worked out we return the entered value
  return res || Number(value);
};

const getRealValue = (value: number | string | undefined, shift: number, precision: number) => {
  // user deleted all input from the field
  if (value === '') {
    return value;
  }
  const res = getValueBasedOnShift(value, shift, precision);
  return _.isFinite(res) ? res : value;
};

type ReduxFormProps = {
  input: {
    name: string,
    value: number | string,
    onChange: (value: number | string) => void,
    onBlur: Function,
  },
  precision: number,
};

type NormalProps = {
  /** Function called when value changes. */
  onChange: (value: number | string) => void,
  /** Number of digits of precision. */
  precision: number,
  /** Form name of the input. */
  name: string,
  /** Value of the input. */
  value: number | string;
};

type Props = ReduxFormProps | NormalProps;

type State = {
  name: string | null,
  value: number | string,
};

/**
 * Allow user input of percentage values, while automatically handling
 * conversions to real values. For example a user entering the number 50 will
 * be translated internally to a 0.5.
 */
export default class PercentageInput extends React.Component<Props, State> {
  static getDisplayValueBasedOnProps(props: Props) {
    const { precision } = props;
    // We do the shifting right (multiplication by 100) for display
    const value = (props as ReduxFormProps).input
      ? (props as ReduxFormProps).input.value
      : (props as NormalProps).value;
    return getRealValue(value, 2, precision);
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      name: (
        (props as ReduxFormProps).input && (props as ReduxFormProps).input.name)
        || (props as NormalProps).name
        || undefined,
      value: PercentageInput.getDisplayValueBasedOnProps(props),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.setState({ value: PercentageInput.getDisplayValueBasedOnProps(newProps) });
  }

  onChange(data: { value: number | string }): void {
    const { precision } = this.props;
    // We do the shift left (division by 100)
    const value = getRealValue(data.value, -2, precision) || data.value;

    if ((this.props as NormalProps).onChange) {
      (this.props as NormalProps).onChange(value);
    }
    if ((this.props as ReduxFormProps).input && (this.props as ReduxFormProps).input.onChange) {
      // Set the divided value to the form
      (this.props as ReduxFormProps).input.onChange(value);
    }

    this.setState({
      value: precision ? _.round(Number(data.value), precision) : data.value,
    });
  }

  // fixes issue where redux form thinks the field isn't dirty yet.
  onBlur(v: any) {
    if ((this.props as ReduxFormProps).input && (this.props as ReduxFormProps).input.onBlur) {
      (this.props as ReduxFormProps).input.onBlur(v);
    }
  }

  render() {
    return (
      <Input
        type="number"
        fluid
        name={this.state.name}
        labelPosition="right"
        label={<Label>%</Label>}
        value={this.state.value}
        // @ts-ignore wants second value to be InputOnChangeData which extends props which makes 0 sense....
        onChange={(e: unknown, data: { value: string }): void => this.onChange(data)}
        onBlur={(_e: any, v: any) => this.onBlur(v)}
        {..._.omit(this.props, 'value', 'input', 'precision', 'onChange')}
      />
    );
  }
}
