import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, Label } from 'buildingBlocks';
import { shiftDecimalSeparator } from 'utils/formattingUtils';

const getValueBasedOnShift = (
  value: number | string,
  shift: number,
  precision?: number,
): number => {
  let res;

  // if precision is set we need to round it on it final displaying form
  // => if we shift to the left, we need to round it before the shifting
  // => if we shift to the right, we need to round it after the shifting
  if (_.isInteger(precision)) {
    if (shift < 0) {
      res = shiftDecimalSeparator(_.round(Number(value), precision), shift);
    } else {
      res = _.round(shiftDecimalSeparator(value, shift), precision);
    }
  } else {
    res = shiftDecimalSeparator(value, shift);
  }

  // if the result of the shifting didn't worked out we return the entered value
  return res || Number(value);
};

export const getRealValue = (
  value: number | string | undefined,
  shift: number,
  precision?: number,
): number | string => {
  // user deleted all input from the field
  if (value === '') {
    return value;
  }
  const res = getValueBasedOnShift(value, shift, precision);
  return _.isFinite(res) ? res : value;
};

type ReactHookFormProps = {
  precision: number;
  value: number;
  onChange: (value: number | '') => void;
};

type Props = ReactHookFormProps;

/**
 * Allow user input of percentage values, while automatically handling
 * conversions to real values. For example a user entering the number 50 will
 * be translated internally to a 0.5.
 */
const PercentageInput = (props: Props) => {
  // We do the shifting right (multiplication by 100) for display
  const [value, setValue] = useState<number | string>(
    getRealValue(props.value, 2, props.precision),
  );

  useEffect(() => {
    const newValue = getRealValue(props.value, 2, props.precision);
    setValue(newValue);
  }, [props.value, props.precision]);

  const onInputChange = (data: { value: string }): void => {
    // We do the shift left (division by 100)
    const newValue = getRealValue(data.value, -2, props.precision) || data.value;
    if (newValue === '') {
      props.onChange('');
    } else {
      props.onChange(+newValue);
    }

    setValue(
      props.precision
        ? _.round(Number(data.value), props.precision)
        : +data.value,
    );
  };

  return (
    <Input
      type="number"
      fluid
      labelPosition="right"
      label={<Label>%</Label>}
      value={value}
      {..._.omit(props, ['value', 'input', 'precision', 'onChange'])}
      onChange={(
        _e: ChangeEvent<HTMLInputElement>,
        data: { value: string },
      ): void => onInputChange(data)}
    />
  );
};

export default PercentageInput;
