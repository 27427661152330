import React from 'react';
import {
  WppActionButton,
  WppIconTrash,
  WppPill,
  WppPillGroup,
} from 'buildingBlocks';
import { deletePanel } from './style';

type Props = {
  handleDeleteRowAction: React.MouseEventHandler<HTMLWppActionButtonElement>
};

const DeleteRowPanel: React.FC<Props> = ({
  handleDeleteRowAction,
}: Props): React.ReactElement => (
  <div style={deletePanel}>
    <WppPillGroup type="display">
      <WppPill removable />
    </WppPillGroup>
    <WppActionButton variant="destructive" onClick={handleDeleteRowAction}>
      Delete
      <WppIconTrash slot="icon-start" />
    </WppActionButton>
  </div>
);

export default DeleteRowPanel;
